import 'expo-dev-client';
/* eslint-disable no-extra-boolean-cast */
import React, { lazy, Suspense, useEffect } from 'react';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import Constants from 'expo-constants';
import { useFonts } from 'expo-font';
import * as SplashScreen from 'expo-splash-screen';
import * as ScreenOrientation from 'expo-screen-orientation';
// eslint-disable-next-line import/no-extraneous-dependencies
import * as Sentry from '@sentry/react-native';
import { ThemeProvider } from 'styled-components/native';
import { ActionSheetProvider } from '@expo/react-native-action-sheet';
import { Dimensions, LogBox, Platform } from 'react-native';
import { RelayEnvironmentProvider } from 'react-relay';
// eslint-disable-next-line import/no-extraneous-dependencies
import OTAConfig from './react-native.config';
import environment from './src/relay/relayEnvironment';
import { getTheme } from './src/themes/new/theme';
import { SnackBar } from './src/components/new';
import { setPerformanceEnable } from './src/utilities/firbaseAnalytics';
import DownloadApp from './src/components/modals/DownloadApp';
import useCommonStore from './src/stores/commonStore';
import Loading from './src/components/loading.component';
import '@expo/metro-runtime';
import AppConstants from './src/utilities/AppConstants';
import { isValueNullOrEmpty } from './src/utilities/Utility';

const AppNavigator = lazy(() => import('./src/navigation/appNavigator'));

const loadGTMScript = () => {
  return new Promise((resolve, reject) => {
    if (typeof document === 'undefined') {
      console.error('This script can only be run in a web environment.');
      // eslint-disable-next-line no-promise-executor-return
      return reject(new Error('Not a web environment'));
    }

    const script = document.createElement('script');
    script.async = true;
    script.src = 'https://www.googletagmanager.com/gtm.js?id=GTM-WB2GMNWJ';
    script.onload = () => {
      console.log('GTM script loaded successfully.');
      resolve(true);
    };
    script.onerror = (error) => {
      console.error('Failed to load GTM script.', error);
      // eslint-disable-next-line prefer-promise-reject-errors
      reject(false);
    };

    document.body.appendChild(script);
  });
};

const insertGTMNoScript = () => {
  return new Promise((resolve, reject) => {
    if (typeof document === 'undefined') {
      console.error('This script can only be run in a web environment.');
      // eslint-disable-next-line no-promise-executor-return
      return reject(new Error('Not a web environment'));
    }

    try {
      const noscript = document.createElement('noscript');
      noscript.innerHTML = `
        <iframe
          src="https://www.googletagmanager.com/ns.html?id=GTM-WB2GMNWJ"
          height="0"
          width="0"
          style="display:none;visibility:hidden"
        ></iframe>
      `;
      const { body } = document;
      if (body) {
        body.insertBefore(noscript, body.firstChild);
        console.log('GTM <noscript> tag inserted successfully.');
        resolve(true);
      } else {
        console.error('Unable to find <body> tag.');
        reject(new Error('Body not found'));
      }
    } catch (error) {
      console.error('Error inserting GTM <noscript> tag:', error);
      // eslint-disable-next-line prefer-promise-reject-errors
      reject(false);
    }
  });
};

// Call both scripts sequentially
const initializeGTM = async () => {
  try {
    const gtmLoaded = await loadGTMScript();
    if (gtmLoaded) {
      console.log('GTM script loaded, now inserting <noscript> tag...');
      await insertGTMNoScript();
      console.log('GTM initialization completed successfully.');
    }
  } catch (error) {
    console.error('Error during GTM initialization:', error);
  }
};

LogBox.ignoreLogs(['Warning: ...']); // Ignore log notification by message
LogBox.ignoreAllLogs(); // Ignore all log notifications
SplashScreen.preventAutoHideAsync();

if (!__DEV__) {
  const { version } = Constants.expoConfig;
  const modifiedAppVersion =
    !isValueNullOrEmpty(OTAConfig.ota) && Platform.OS !== 'web'
      ? `${version}-${OTAConfig.ota}`
      : version;
  Sentry.init({
    dsn: 'https://44049234ccce4c7da2f97dc494a59d00@o489978.ingest.us.sentry.io/5553134',
    enableTracing: true,
    enableAutoPerformanceTracing: true,
    enableNative: true,
    enableCaptureFailedRequests: true,
    enabled: true,
    debug: !__DEV__,
    environment: AppConstants.APP_ENV,
    tracesSampleRate: 1,
    release: modifiedAppVersion,
  });
}

function App() {
  const theme = useCommonStore((state) => state.theme);
  const styledTheme = getTheme(theme);
  const screenOrientation = useCommonStore((state) => state.orientation);
  const setScreenOrientation = useCommonStore((state) => state.setOrientation);

  const [loaded] = useFonts({
    'NewYork-Regular': require('./assets/fonts/NewYork-Regular.ttf'),
    'MessinaSans-Bold': require('./assets/fonts/MessinaSans-Bold.otf'),
    'MessinaSans-Regular': require('./assets/fonts/MessinaSans-Regular.otf'),
    'CircularStd-Regular': require('./assets/fonts/CircularStd-Book.otf'),
    'CircularStd-Medium': require('./assets/fonts/CircularStd-Medium.otf'),
    'MixtaDidoneAlt-Medium': require('./assets/fonts/MixtaDidoneAlt-Medium.otf'),
    'Gotham-Regular': require('./assets/fonts/Gotham-Book.otf'),
    'Gotham-Medium': require('./assets/fonts/Gotham-Medium.otf'),
    'DomaineText-RegularItalic': require('./assets/fonts/DomaineText-RegularItalic.otf'),
    'UniversNextPro-Regular': require('./assets/fonts/UniversNextPro-Regular.otf'),
    IcoMoon: require('./assets/fonts/icomoon/fonts/icomoon.ttf'),
  });

  const windowWidth = Dimensions.get('window').width;

  useEffect(() => {
    if (Platform.OS === 'web') {
      if (AppConstants.APP_ENV === 'production') {
        const script = document.createElement('script');
        script.innerHTML = `!function(f,b,e,v,n,t,s){if(f.fbq)return;n=f.fbq=function(){n.callMethod?n.callMethod.apply(n,arguments):n.queue.push(arguments)};if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';n.queue=[];t=b.createElement(e);t.async=!0;t.src=v;s=b.getElementsByTagName(e)[0];s.parentNode.insertBefore(t,s)}(window, document,'script','https://connect.facebook.net/en_US/fbevents.js');fbq('init', '2116460215362701');fbq('track', 'PageView');`;
        document.head.appendChild(script);
      } else {
        const script = document.createElement('script');
        script.innerHTML = `!function(f,b,e,v,n,t,s){if(f.fbq)return;n=f.fbq=function(){n.callMethod?n.callMethod.apply(n,arguments):n.queue.push(arguments)};if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';n.queue=[];t=b.createElement(e);t.async=!0;t.src=v;s=b.getElementsByTagName(e)[0];s.parentNode.insertBefore(t,s)}(window, document,'script','https://connect.facebook.net/en_US/fbevents.js');fbq('init', '28467469842852213');fbq('track', 'PageView');`;
        document.head.appendChild(script);
      }
    }
  }, []);

  const hideSplashScreen = async () => {
    await SplashScreen.hideAsync();
  };

  useEffect(() => {
    setPerformanceEnable(true);
    if (Platform.OS !== 'web') {
      ScreenOrientation.lockAsync(ScreenOrientation.OrientationLock.PORTRAIT);
    }
    ScreenOrientation.addOrientationChangeListener((event) => {
      setScreenOrientation(event?.orientationInfo?.orientation);
    });
    ScreenOrientation.getOrientationAsync()
      .then((value) => {
        setScreenOrientation(value);
      })
      .catch((err) => {
        setScreenOrientation(0);
        throw err;
      });
    return () => {
      ScreenOrientation.removeOrientationChangeListeners();
      setPerformanceEnable(false);
    };
  }, []);

  useEffect(() => {
    // Once our data is ready, hide the Splash Screen
    const checkUpdate = async () => {
      await hideSplashScreen();
    };
    if (loaded) {
      checkUpdate();
    }
    if (Platform.OS === 'web') {
      initializeGTM();
    }
  }, [loaded]);

  if (!loaded) {
    return null;
  }

  return (
    <ThemeProvider theme={styledTheme}>
      <ActionSheetProvider>
        <RelayEnvironmentProvider environment={environment}>
          <SafeAreaProvider
            style={
              windowWidth > 1200 && screenOrientation < 3
                ? { width: 375, alignSelf: 'center' }
                : {}
            }
          >
            <Suspense fallback={<Loading />}>
              <AppNavigator />
            </Suspense>
            <SnackBar />
            <DownloadApp />
          </SafeAreaProvider>
        </RelayEnvironmentProvider>
      </ActionSheetProvider>
    </ThemeProvider>
  );
}

export default Sentry.wrap(App);
