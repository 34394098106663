/* eslint-disable-next-line camelcase */
import { PixelRatio, Platform, Linking } from 'react-native';
import { commitMutation } from 'react-relay';
import moment from 'moment';
import { isEmpty } from 'lodash';
import imageConfig from '../../imagekit.config';
import { updateParking } from '../relay/parkingApi';
import { UserRole } from '../stores/userStore';
import { CampusLocationInterface } from '../interfaces/CampusLocationInterface';
import environment from '../relay/relayEnvironment';
import { getTheme } from '../themes/new/theme';
import AppConstants, { Environments } from './AppConstants';
import { MealTimeEnum } from '../screens/restaurantPartner/discounts/helper';
import { isValueNullOrEmpty } from './Utility';
import { defaultConatactNumber32ND } from './Constant';

/* ----------------------- Enums Start ------------------------------  */

export enum JUSPAY_ORDER_STATUS {
  // ----------PENDING------------
  PENDING_VBV = 'PENDING_VBV',
  AUTHORIZING = 'AUTHORIZING',
  STARTED = 'STARTED',
  NEW = 'NEW',
  CAPTURE_INITIATED = 'CAPTURE_INITIATED', // pre-auth
  VOID_INITIATED = 'VOID_INITIATED', // pre-auth
  PENDING = 'PENDING', // refund pending
  MANUAL_REVIEW = 'MANUAL_REVIEW', // refund pending for more than 10 days

  // ----------------SUCCESSFUL----------------
  CHARGED = 'CHARGED',
  COD_INITIATED = 'COD_INITIATED',
  PARTIAL_CHARGED = 'PARTIAL_CHARGED',
  AUTHORIZED = 'AUTHORIZED', // pre-auth
  VOIDED = 'VOIDED', // pre-auth
  SUCCESS = 'SUCCESS', // refund success

  // -----------------FAILED----------------
  AUTHENTICATION_FAILED = 'AUTHENTICATION_FAILED',
  AUTHORIZATION_FAILED = 'AUTHORIZATION_FAILED',
  JUSPAY_DECLINED = 'JUSPAY_DECLINED',
  CAPTURE_FAILED = 'CAPTURE_FAILED', // pre-auth
  VOID_FAILED = 'VOID_FAILED', // pre-auth
  FAILURE = 'FAILURE', // refund failed

  // -----------------------REFUNDED---------------------
  AUTO_REFUNDED = 'AUTO_REFUNDED',

  // ----------------ERRORED-----------------------
  NOT_FOUND = 'NOT_FOUND',
}

export enum barCodeTypes {
  Code39 = 'org.iso.Code39',
  qrCode = 'org.iso.QRCode',
  qr = 'qr',
  code39 = 'code39',
}

export enum mediaTypes {
  image = 'image',
  video = 'video',
  IMAGE = 'IMAGE',
  VIDEO = 'VIDEO',
  LOTTIE = 'LOTTIE',
}

export enum investPaymentTypes {
  PAID = 'Paid',
  INPROGRESS = 'In_Progress',
  PENDING = 'Pending',
}

export enum taskHistoryActionTypes {
  created = 'created',
  onboarded = 'onboarded',
  assigned = 'assigned',
  accepted = 'accepted',
  started = 'started',
  arrived = 'arrived',
  completed = 'completed',
  abort = 'abort',
  cancelled = 'cancelled',
  incomplete = 'incomplete',
  EXITED = 'exited',
  parking = 'parking',
  parked = 'parked',
  reparking = 'reparking',
}

export enum cashierEntryEnums {
  PARKING = 'PARKING',
  DRIVER = 'DRIVER',
  ADDED = 'ADDED',
  TAXI = 'TAXI',
  ANPR = 'ANPR',
  MANUAL = 'MANUAL',
  FASTAG = 'FASTag',
}

export enum pointsTypes {
  parkDelay = 'PARK_DELAY',
  campusVisit = 'CAMPUS_VISIT',
}

export enum EventTransactionStatus {
  CREATED = 'created',
  PAID = 'paid',
  DELIVERED = 'delivered',
  CANCELLED = 'cancelled',
}

export enum taskTypes {
  park = 'park',
  recall = 'recall',
  repark = 'repark',
}

export enum PaymentTypes {
  FOC = 'FOC',
  FASTAG = 'FASTAG',
  CASH = 'CASH',
  ONLINE = 'ONLINE',
  APP = 'APP',
}

export enum FocReason {
  FREE_PARKING_TICKET = 'FREE_PARKING_TICKET',
  EMPLOYEE_EXIT = 'EMPLOYEE_EXIT',
  VALET_EXIT = 'VALET_EXIT',
  VIP_EXIT = 'VIP_EXIT',
  MISSED_EXIT = 'MISSED_EXIT',
  CUSTOMER_ISSUE = 'CUSTOMER_ISSUE',
  OTHER = 'OTHER',
}

export enum ParkingStatus {
  INCOMING = 'INCOMING',
  PARKED = 'PARKED',
  OUTGOING = 'OUTGOING',
  OUT = 'OUT',
  MISSED_EXIT = 'MISSED_EXIT',
  FAULTY_PARK = 'FAULTY_PARK',
  FAULTY_RECALL = 'FAULTY_RECALL',
}

export enum EmailTypes {
  PERSONAL = 'PERSONAL',
  WORK = 'WORK',
}

export enum StaffRewardCategory {
  CONTROLLER_RECALL_ARRIVAL = 'CONTROLLER_RECALL_ARRIVAL',
  CONTROLLER_RECALL_ASSIGNMENT = 'CONTROLLER_RECALL_ASSIGNMENT',
  CONTROLLER_REPARKING_COMPLETION = 'CONTROLLER_REPARKING_COMPLETION',
  CONTROLLER_PARKING_COMPLETION = 'CONTROLLER_PARKING_COMPLETION',
  CONTROLLER_PARKING_ONBOARDING = 'CONTROLLER_PARKING_ONBOARDING',
  CONTROLLER_PARKING_ASSIGNMENT = 'CONTROLLER_PARKING_ASSIGNMENT',
  VALET_RECALL = 'VALET_RECALL',
  VALET_REPARK = 'VALET_REPARK',
  VALET_PARK = 'VALET_PARK',
}

export enum TaskStatus {
  ON_TIME = 'ON_TIME',
  DELAYED = 'DELAYED',
}

export enum RestaurantCollectionCategory {
  RECOMMENDED = 'RECOMMENDED',
  DATE_NIGHT = 'DATE_NIGHT',
  OUTDOOR_SEATING = 'OUTDOOR_SEATING',
  UNIQUE_DINEIN = 'UNIQUE_DINEIN',
  SERVES_ALCOHOL = 'SERVES_ALCOHOL',
  FINE_DINING = 'FINE_DINING',
  CAFES = 'CAFES',
}

export enum MediaGalleryTypes {
  AMBIENCE = 'Ambience',
  FOUNDER_STORY = 'Founder_Story',
  FOOD = 'Food',
  AWARDS_RECOGNITIONS = 'Awards___Recognitions',
}

export enum DeliveryStatus {
  DELIVERED = 'DELIVERED',
  UNDELIVERED = 'UNDELIVERED',
}

export enum ColorStatusEnum {
  SUCCESS = 'success',
  ERROR = 'error',
  WARNING = 'warning',
  DEFAULT = 'default',
}

export enum PeriodEnum {
  DAY = 'DAY',
  WEEK = 'WEEK',
  MONTH = 'MONTH',
}

export enum BlackMembershipStatus {
  ACTIVE = 'ACTIVE',
  PAUSED = 'PAUSED',
  EXPIRED = 'EXPIRED',
}
export enum MembershipTypes {
  PLUS_MEMBERSHIP = 'a_32ND_BLACK_MEMBERSHIP',
}
export enum TicketStatus {
  CANCELLED = 'CANCELLED',
  CLAIMED = 'CLAIMED',
  EXPIRED = 'EXPIRED',
  ACTIVE = 'ACTIVE',
}

export enum ticketType {
  EVENT_TICKET = 'EVENT_TICKET',
  REWARD_TICKET = 'REWARD_TICKET',
}

export enum foodType {
  VEG = 'veg',
  NON_VEG = 'non_veg',
  VEGAN = 'vegan',
  CONTAINS_EGG = 'contains-egg',
}

export enum rewardOriginEnum {
  trackScreen = 'trackScreen',
  slotMachine = 'games_slotMachine',
  firstLogin = 'First Login',
  trackCard = 'trackCard',
  trackingSurveyReward = 'trackingSurveyReward',
}

export enum walletTransactionEnum {
  firstLogin = 'FIRST_LOGIN',
  firstTxn = 'FIRST_TXN',
  subsequentTxn = 'SUBSEQUENT_TXN',
  campusVisit = 'CAMPUS_VISIT',
  slotSpin = 'SLOT_SPIN',
  feedbackGiven = 'FEEDBACK_GIVEN',
  pointsExpiry = 'POINTS_EXPIRY',
  foorOrder = 'FOOD_ORDER',
  store = 'STORE',
  restaurant = 'RESTAURANT',
  other = 'OTHER',
  refund = 'REFUND',
}

export enum transactionTypeEnum {
  credit = 'CREDIT',
  debit = 'DEBIT',
}

export enum VehicleStatusTagEnum {
  recalled = 'Recalled',
  parked = 'Parked',
  parking = 'Parking',
  out = 'Out',
  OUT = 'OUT',
  reParking = 'Re-Parking',
  reParked = 'Re-Parked',
  created = 'created',
  accepted = 'accepted',
  started = 'started',
}

export enum paymentOrderTypeEnum {
  store = 'STORE',
  restaurant = 'RESTAURANT',
  foorOrder = 'FOOD_ORDER',
  event = 'EVENT',
  popupStore = 'POPUP_STORE',
  PARKING = 'PARKING',
  EVENT = 'EVENT',
  POPUPSTORE = 'POPUP_STORE',
  ADDONS = 'ADDONS',
  INVESTMENT_LEAD = 'INVESTMENT_LEAD',
  PAYMENT_ORDER = 'PAYMENT_ORDER',
  POPUP_ORDER = 'POPUP_ORDER',
  RESTAURANT = 'RESTAURANT',
}

export enum PaymentGatewayType {
  RAZORPAY = 'RAZORPAY',
  PAYTMQR = 'PAYTMQR',
  CASH = 'CASH',
  parking = 'PARKING',
  BILL_UPLOAD = 'BILL_UPLOAD',
  JUSPAY = 'JUSPAY',
}

export enum PaymentStatusEnum {
  SUCCESSFUL = 'SUCCESSFUL',
  FAILED = 'FAILED',
  PENDING = 'PENDING',
  REFUNDED = 'REFUNDED',
}

export enum DateFormatsEnum {
  DateMonthYear = 'DMY',
  DayDateMonth = 'DDM',
  DateMonthYearDob = 'DMYD',
  DayDateMonthYear = 'DDMY',
  RangeMonthYear = 'RMY',
  MonthYear = 'MY',
  Year = 'Y',
  MonthFull = 'M',
  TIME = 'TIME',
  MS = 'MILLISECONDS',
}

export enum RestaurantType {
  RESTAURANT = 'RESTAURANT',
  STORE = 'STORE',
}

export enum NOTABLETOSENTMESSAGEOPTIONSENUM {
  NOT_ENOUGH_LOYALTY_POINTS = 'NOTENOUGHLOYALTYPOINTS',
  NOT_VISITED_CAMPUS = 'NOTVISITEDCAMPUS',
  INVITE_ALREADY_SENT = 'INVITEALREADYSENT',
}

export enum INVESTMENTKEYS {
  IMAGEFRAME = 'IMAGEFRAME',
  VIDEOFRAME = 'VIDEOFRAME',
  HORIZONTALFRAME = 'HORIZONTALFRAME',
  LARGEIMAGE = 'LARGEIMAGE',
  NEARBY = 'NEARBY',
  VIDEOTHUMBNAIL = 'VIDEOTHUMBNAIL',
  VIDEOSLIDER = 'VIDEOSLIDER',
  TRACKER = 'TRACKER',
  ARTICLE = 'ARTICLE',
  FRACTIONAL_OWNERSHIP = 'FRACTIONAL_OWNERSHIP',
  FULL_OWNERSHIP = 'FULL_OWNERSHIP',
  INTRO = 'intro',
  AMENITIES = 'amenities',
  INVESTMENT = 'investment',
  SUBSCRIPTION = 'subscription',
  SUBSCRIPTIONV2 = 'subscriptionV2',
  INVESTMENTSRECORD = 'INVESTMENTSRECORD',
  INVESTMENTPROPERTYINFO = 'INVESTMENTPROPERTYINFO',
  INVESTMENTASSOCIATES = 'INVESTMENTASSOCIATES',
  INVESTMENTDETAILSSECTION = 'INVESTMENTDETAILSSECTION',
  HIGHLIGHTS = 'highlights',
  HIGHLIGHTS_VIDEO = 'HIGHLIGHTS_VIDEO',
  GALLERY = 'gallery',
  LOCATION = 'location',
  NEAR_BY = 'nearby',
  PROMISE = 'promise',
  RATINGS_AND_REVIEWS = 'RATINGS_AND_REVIEWS',
  PARTNERS = 'partners',
  PROCESS = 'process',
  PRESS = 'press',
  DOCUMENTS = 'documents',
  ABOUT_US = 'about_us',
  CTA = 'cta',
  APPLICATION = 'application',
  COMPAREINVESTMENTS = 'compare_investments',
  INVESTMENT_PARTNERS = 'INVESTMENT_PARTNERS',
  APPLICATION_LETTER = 'APPLICATION_LETTER',
  TRANSACTION_DETAILS = 'TRANSACTION_DETAILS',
  SAMPLE_OPPORTUNITY_LETTER = 'SAMPLE_OPPORTUNITY_LETTER',
  SAMPLE_BUYBACK_LETTER = 'SAMPLE_BUYBACK_LETTER',
  SAMPLE_T_AND_C = 'SAMPLE_T_AND_C',
  SAMPLE_SHARE_CERTIFICATE = 'SAMPLE_SHARE_CERTIFICATE',
  WHYINVESTSECTION = 'WHYINVESTSECTION',
  SATISFIEDINVESTORSSECTION = 'SATISFIEDINVESTORSSECTION',
  SUCCESS_METRICS = 'SUCCESS_METRICS',
  FOUNDERS_SPEAK = 'FOUNDERS_SPEAK',
}

export enum SURVEY_ID {
  PRODUCT = '32NDPRODUCT',
  INVEST_VAGATOR = 'INVEST_VAGATOR',
}

export enum TYPEFORMID {
  PRODUCT = 'BdMNFExQ',
  PRODUCT_DEV = 'NTkABo0o',
  INVEST = 'RJNGK2aE',
  INVEST_DEV = 'fskNsVKg',
}

export enum COUPON_TYPE {
  FLAT_CASHBACK = 'FLAT_CASHBACK',
  FLAT_DISCOUNT = 'FLAT_DISCOUNT',
  PERCENTAGE_OFF = 'PERCENTAGE_OFF',
  PERCENTAGE_CASHBACK = 'PERCENTAGE_CASHBACK',
}

export enum INVESTMENT_APPLICATION_STATUS {
  CREATED = 'CREATED',
  APPLICATION_RECEIVED = 'APPLICATION_RECEIVED',
  REFUND_INITIATED = 'REFUND_INITIATED',
  REFUND_PROCESSED = 'REFUND_PROCESSED',
  INVESTMENT_CONFIRMED = 'INVESTMENT_CONFIRMED',
  INVESTMENT_COMPLETED = 'INVESTMENT_COMPLETED',
}

export enum SCAN_STATUS {
  IDLE = 'IDLE',
  REQUESTED = 'REQUESTED',
  STARTED = 'STARTED',
  PROCESSING = 'PROCESSING',
  SUCCESS = 'SUCCESS',
  FAILURE = 'FAILURE',
}

export enum UPLOAD_BILL_STATUS {
  CREATED = 'CREATED',
  PROCESSING = 'PROCESSING',
  PROCESSED = 'PROCESSING',
  PROCESSING_FAILED = 'PROCESSING_FAILED',
  MATCHED = 'MATCHED',
  UNMATCHED = 'UNMATCHED',
  VERIFIED = 'VERIFIED',
  UNVERIFIED = 'UNVERIFIED',
  VERIFICATION_FAILED = 'VERIFICATION_FAILED',
}

export const JUSPAY_PAYMENT_STATUS = {
  PENDING: [
    JUSPAY_ORDER_STATUS.PENDING_VBV,
    JUSPAY_ORDER_STATUS.AUTHORIZING,
    JUSPAY_ORDER_STATUS.STARTED,
    JUSPAY_ORDER_STATUS.NEW,
    JUSPAY_ORDER_STATUS.CAPTURE_INITIATED,
    JUSPAY_ORDER_STATUS.VOID_INITIATED,
    JUSPAY_ORDER_STATUS.PENDING,
    JUSPAY_ORDER_STATUS.MANUAL_REVIEW,
  ],

  SUCESSFUL: [
    JUSPAY_ORDER_STATUS.CHARGED,
    JUSPAY_ORDER_STATUS.COD_INITIATED,
    JUSPAY_ORDER_STATUS.PARTIAL_CHARGED,
    JUSPAY_ORDER_STATUS.AUTHORIZED,
    JUSPAY_ORDER_STATUS.VOIDED,
    JUSPAY_ORDER_STATUS.SUCCESS,
  ],

  FAILED: [
    JUSPAY_ORDER_STATUS.AUTHENTICATION_FAILED,
    JUSPAY_ORDER_STATUS.AUTHORIZATION_FAILED,
    JUSPAY_ORDER_STATUS.JUSPAY_DECLINED,
    JUSPAY_ORDER_STATUS.CAPTURE_FAILED,
    JUSPAY_ORDER_STATUS.VOID_FAILED,
    JUSPAY_ORDER_STATUS.FAILURE,
    JUSPAY_ORDER_STATUS.NOT_FOUND,
  ],

  REFUNDED: [JUSPAY_ORDER_STATUS.AUTO_REFUNDED],
};

export const JUSPAY_SDK_ERROR_CODES = [
  'JP_001',
  'JP_002',
  'JP_003',
  'JP_004',
  'JP_007',
  'JP_008',
  'JP_009',
  'JP_010',
  'JP_011',
  'JP_012',
  'JP_016',
  'JP_018',
  'JP_019',
  'JP_020',
  'JP_005',
  'JP_015',
  'JP_006',
  'JP_013',
  'JP_014',
  'JP_022',
];
export enum SPIN_TYPE {
  PARKING = 'PARKING',
  EVENT_BOOKING = 'EVENT_BOOKING',
  DAILY_SPIN = 'DAILY_SPIN',
}

export enum COUPON_STATUS {
  ACTIVE = 'ACTIVE',
  CLAIMED = 'CLAIMED',
  EXPIRED = 'EXPIRED',
}

export enum REDEEMABLE_REFTYPE {
  STORE = 'STORE',
  RESTAURANT = 'RESTAURANT',
  FOOD_ORDER = 'FOOD_ORDER',
  PARKING = 'PARKING',
  EVENT = 'EVENT',
  POPUP_STORE = 'POPUP_STORE',
  ADDONS = 'ADDONS',
  INVESTMENT_LEAD = 'INVESTMENT_LEAD',
  POPUP_ORDER = 'POPUP_ORDER',
}

export enum EventRatingsTopic {
  OVERALL = 'OVERALL',
  EVENT_EXPERIENCE = 'EVENT_EXPERIENCE',
  EVENT_VENUE = 'EVENT_VENUE',
  EVENT_INSTRUCTOR = 'EVENT_INSTRUCTOR',
}

export enum WALLET_TXN_TYPE {
  POINTS = 'points',
  CREDITS = 'credits',
}

/* ----------------------- Enums End ------------------------------  */

/* ----------------------- Helper function Start ------------------------------  */

export const defaultPhoneNumberPrefix = '+91';

export const AuthSessionHandler = (error) => {
  let errorCode = '';
  if (error.source) {
    errorCode = error.source.errors[0].extensions.code;
  }
  let logOut = false;
  if (errorCode !== '' && errorCode === 'UNAUTHENTICATED') {
    logOut = true;
    return logOut;
  }
  return logOut;
};

export const findTaskHistoryLastAction = (task) => {
  const lastHistoryItem = task?.history.slice(-1)[0];
  const lastAction = lastHistoryItem?.action;
  return lastAction;
};

export const timeDifference = (time1, time2) => {
  const time = new Date(time2);
  const difference = time1.getTime() - time.getTime();
  return difference;
};

export const formatTimeDifference1 = (currentTime, created_at) => {
  let difference = timeDifference(currentTime, created_at);
  const daysDifference = Math.floor(difference / 1000 / 60 / 60 / 24);
  if (daysDifference === -1) {
    return 'Just now';
  }
  difference -= daysDifference * 1000 * 60 * 60 * 24;

  const hoursDifference = Math.floor(difference / 1000 / 60 / 60);
  difference -= hoursDifference * 1000 * 60 * 60;

  const minutesDifference = Math.floor(difference / 1000 / 60);
  let text = '';
  if (daysDifference >= 1) {
    if (daysDifference === 1) {
      text = `${daysDifference} day ago`;
    } else {
      text = `${daysDifference} days ago`;
    }
  } else if (hoursDifference >= 1) {
    text = `${hoursDifference} hr ${minutesDifference} mins ago`;
  } else if (minutesDifference > 2) {
    text = `${minutesDifference} mins ago`;
  } else {
    text = 'Just now';
  }
  return text;
};

export const formatTimeDifference2 = (currentTime, created_at) => {
  const difference = timeDifference(currentTime, created_at);
  let text = '';
  if (Math.floor(difference / 1000) < 60) {
    text = `${Math.floor(difference / 1000)}s`;
  } else if (Math.floor(difference / 1000 / 60) < 60) {
    text = `${Math.floor(difference / 1000 / 60)}m`;
  } else if (Math.floor(difference / 1000 / 60 / 60) <= 24) {
    text = `${Math.floor(difference / 1000 / 60 / 60)}h`;
  } else {
    text = `${Math.floor(difference / 1000 / 60 / 60 / 24)}d`;
  }
  return text;
};

export const formatTimeDifference3 = (
  currentTime,
  created_at,
  fixedETA = 0,
) => {
  const difference = formatTimeDifference2(currentTime, created_at);
  let text = '';
  if (difference.slice(-1) === 's') {
    text = `${fixedETA}m`;
  } else if (difference.slice(-1) === 'm') {
    // eslint-disable-next-line radix
    const elaplsed = parseInt(difference.slice(0, -1));
    if (elaplsed >= fixedETA) {
      text = '0m';
    } else {
      text = `${fixedETA - elaplsed}m`;
    }
  } else {
    text = '0m';
  }
  return text;
};

export const minutesDifferenceInDate = (event_at) => {
  const newDate = new Date(event_at);
  const currentDate = new Date();
  const difference = currentDate.getTime() - newDate.getTime();
  const minutesDifference = Math.floor(difference / 1000 / 60);
  return minutesDifference;
};

export const checkDateBreach = (event_at: string, days: number) => {
  // eslint-disable-next-line camelcase
  const unix_date = moment().unix();
  // eslint-disable-next-line camelcase
  const new_date = moment(event_at).add(days, 'days').unix();
  // eslint-disable-next-line camelcase
  const isDateBreach = unix_date > new_date;
  return isDateBreach;
};

export const secondsDifferenceInDate = (event_at) => {
  const newDate = new Date(event_at);
  const currentDate = new Date();
  const difference = newDate.getTime() - currentDate.getTime();
  const secondsDifference = Math.floor(difference / 1000);
  return secondsDifference;
};

const tranformationArr = (width: number, ar: string) => {
  if (!isValueNullOrEmpty(ar)) {
    return [
      {
        width: String(PixelRatio.getPixelSizeForLayoutSize(width)),
        aspectRatio: ar,
        format: 'webp',
      },
    ];
  }
  return [
    {
      width: String(PixelRatio.getPixelSizeForLayoutSize(width)),
      format: 'webp',
    },
  ];
};

// note: ar = aspectRatio = cound be in 'width-height' format
export const imageTransformation = (img: string, width: number, ar = '') => {
  const imageKitUrl = imageConfig.url({
    src: img,
    transformation: tranformationArr(width, ar),
  });
  return imageKitUrl;
};

const tranformationArrVideo = (width: number, aspectRatio: number) => {
  const ar =
    aspectRatio > 0
      ? `${Math.trunc(width)}-${Math.trunc(aspectRatio * width)}`
      : '';
  if (!isValueNullOrEmpty(ar)) {
    return [
      {
        width: String(PixelRatio.getPixelSizeForLayoutSize(width)),
        aspectRatio: ar,
      },
    ];
  }
  return [
    {
      width: String(PixelRatio.getPixelSizeForLayoutSize(width)),
    },
  ];
};

export const videoTransformation = (
  url: string,
  width: number,
  aspectRatio: number,
) => {
  const imageKitUrl = imageConfig.url({
    src: url,
    transformation: tranformationArrVideo(width, aspectRatio),
  });
  return imageKitUrl;
};

export const Capitalise = (string) =>
  string?.[0]?.toUpperCase() + string?.slice(1);

export const formatPlateNo = (vrn) => {
  if (!vrn) {
    return '';
  }
  let plate = vrn.toUpperCase().replace(/\s+/g, '');
  if (plate.length > 8) {
    plate = `${plate.substring(0, 2)} ${plate.substring(2, 4)} ${
      plate.substring(4, plate.length - 4).length > 2
        ? 'xx'
        : plate.substring(4, plate.length - 4)
    } ${plate.substring(plate.length - 4, plate.length)}`;
  } else if (plate.length > 6) {
    plate = `${plate.substring(0, 2)} ${plate.substring(
      2,
      plate.length - 4,
    )} ${plate.substring(plate.length - 4, plate.length)}`;
  }
  return plate;
};

export const getRole = (name) => {
  switch (name) {
    case UserRole.VALET_SUPERVISOR:
      return 'Supervisor';
    case UserRole.USER:
      return 'User';
    case UserRole.VALET:
      return 'Valet';
    case UserRole.ADMIN:
      return 'Admin';
    case UserRole.CASHIER:
      return 'Cashier';
    case UserRole.CONTROLLER:
      return 'Controller';
    case 'PARK':
      return 'Parking';
    case 'RECALL':
      return 'Recall';
    default:
      throw new Error('Invalid role!');
  }
};

export const removeDuplicates = (originalArray, key) =>
  originalArray.reduce((arr, item) => {
    const removed = arr.filter((i) => i[key] !== item[key]);
    return [...removed, item];
  }, []);

export const getOccupancyPercentageColor = (
  occupancyPercentage,
  totalColorDot,
  dotColor,
) => {
  if (occupancyPercentage < 25) {
    totalColorDot = 1;
    dotColor = getTheme().colors.success[500];
  } else if (occupancyPercentage > 25 && occupancyPercentage < 60) {
    totalColorDot = 2;
    dotColor = getTheme().colors.success[500];
  } else if (occupancyPercentage >= 60 && occupancyPercentage < 80) {
    totalColorDot = 3;
    dotColor = getTheme().colors.warning[500];
  } else if (occupancyPercentage >= 80) {
    totalColorDot = 4;
    dotColor = getTheme().colors.error[500];
  }
  return {
    totalColorDot,
    dotColor,
  };
};

export const calculateTime = (duration) => {
  const milliseconds = Math.floor((duration % 1000) / 100);
  const seconds = Math.floor((duration / 1000) % 60);
  const minutes = Math.floor((duration / (1000 * 60)) % 60);
  const hours = Math.floor((duration / (1000 * 60 * 60)) % 24);
  const days = Math.floor(duration / (1000 * 60 * 60 * 24));

  // hours = (hours < 10) ? `0${hours}` : hours;
  // minutes = (minutes < 10) ? `0${minutes}` : minutes;
  // seconds = (seconds < 10) ? `0${seconds}` : seconds;
  let time = '0m';
  if (hours < 1 && minutes > 0 && minutes < 45 && days < 1) {
    time = `${Math.ceil(minutes / 15) * 15}m`;
  } else if (hours < 1 && minutes >= 45 && minutes < 60 && days < 1) {
    time = `${hours + 1}h`;
  } else if (hours >= 1 && hours < 24 && days < 1) {
    if (minutes > 0 && minutes < 45) {
      time = `${hours}h ${Math.ceil(minutes / 15) * 15}m`;
    } else if (minutes >= 45 && minutes <= 60) {
      time = `${hours === 23 ? `${days + 1}d` : `${hours + 1}h`}`;
    } else {
      time = `${hours}h`;
    }
  } else if (days >= 1) {
    if (minutes >= 45 && minutes < 60) {
      if (hours === 23) {
        time = `${days + 1}d`;
      } else {
        time = `${days}d ${hours + 1}h`;
      }
    } else if (hours >= 1 && minutes < 45) {
      time = `${days}d ${hours}h`;
    } else {
      time = `${days}d`;
    }
  }

  return `${time}`;
};

export const navigateBack = (navigation, role) => {
  if (navigation.canGoBack()) {
    navigation.goBack();
  } else {
    // navigation.navigate(role);
    switch (role) {
      case UserRole.USER:
        navigation.replace(UserRole.USER);
        break;
      case UserRole.VALET:
        navigation.replace(UserRole.VALET);
        break;
      case UserRole.VALET_SUPERVISOR:
        navigation.replace(UserRole.VALET_SUPERVISOR);
        break;
      case UserRole.CONTROLLER:
        navigation.replace(UserRole.VALET_SUPERVISOR);
        break;
      case UserRole.CASHIER:
        navigation.replace(UserRole.CASHIER);
        break;
      case UserRole.AUDITOR:
        navigation.replace(UserRole.AUDITOR);
        break;
      case UserRole.PARTNER:
        navigation.replace(UserRole.PARTNER);
        break;
      case UserRole.MOMENT_STAFF_PARTNER:
        navigation.replace(UserRole.MOMENT_STAFF_PARTNER);
        break;
      case UserRole.RESTAURANT_PARTNER:
        navigation.replace(UserRole.RESTAURANT_PARTNER);
        break;
      case UserRole.RESTAURANT_PARTNER_MOD:
        navigation.replace(UserRole.RESTAURANT_PARTNER_MOD);
        break;
      case UserRole.RESTAURANT_PARTNER_STAFF:
        navigation.replace(UserRole.RESTAURANT_PARTNER_STAFF);
        break;
      case UserRole.BILL_UPLOAD_REVIEWER:
        navigation.replace(UserRole.BILL_UPLOAD_REVIEWER);
        break;
      case UserRole.PHOTO_MOMENTS_STAFF:
        navigation.replace('staff');
        break;
      case null:
        navigation.replace('user');
        break;
      default:
        navigation.replace('user');
      // console.log('Invalid role.');
    }
  }
};

export const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    );
};

export const markExitRecalledTask = (task, callBack) => {
  const parkingData = { record: { status: 'OUT' }, id: task.parkingId };
  updateParking(
    parkingData,
    (response) => {
      callBack(task);
    },
    (err) => {
      // console.log(err);
    },
  );
};

export const getOptions = (opts) => {
  let str = '';
  opts.forEach((item, index) => {
    if (index === opts.length - 1) {
      str += item.name;
      return;
    }
    str += `${item.name}, `;
  });
  return str;
};

export const phoneFormat = (input) => {
  // Strip all characters from the input except digits
  input = input.replace(/\D/g, '');
  // input = input.substring(0,10);
  const size = input.length;
  if (size > 5) {
    // input = input.substring(0,5)+' '+input.substring(5,10);
    input = `${input.substring(0, 5)} ${input.substring(5)}`;
  }
  return input;
};
export const formatNumber = (number, precision) => {
  const abbrev = ['', 'k', 'm', 'b', 't'];
  const unrangifiedOrder = Math.floor(Math.log10(Math.abs(number)) / 3);
  const order = Math.max(0, Math.min(unrangifiedOrder, abbrev.length - 1));
  const suffix = abbrev[order];

  return (number / 10 ** (order * 3)).toFixed(precision) + suffix;
};

export const roundOffToMultiple = (number, multiple) => {
  const res = number - (number % multiple);
  return Math.min(res, number);
};

export function convertTimeStringToDate(timeString: string): Date {
  // Split the time string into hours, minutes, and AM/PM parts
  const [hourString, minuteString, period] = timeString.split(/:|\s/);

  // Convert the hour string to a number
  let hour = parseInt(hourString, 10);

  // If the period is "PM" and the hour is not 12, add 12 to the hour
  if (period === 'PM' && hour !== 12) {
    hour += 12;
  }

  // If the period is "AM" and the hour is 12, set the hour to 0
  if (period === 'AM' && hour === 12) {
    hour = 0;
  }

  // Create a new Date object with the hour and minute values
  const dateObj = new Date();
  dateObj.setHours(hour, parseInt(minuteString, 10), 0, 0);

  return dateObj;
}

export function getMinutesBetweenDates(date1: Date, date2: Date): number {
  const diffInMs = Math.abs(date2.getTime() - date1.getTime());
  const diffInMinutes = Math.ceil(diffInMs / 1000 / 60);
  return diffInMinutes;
}

// eslint-disable-next-line consistent-return
export const getDifferenceInMins = (time1, time2) => {
  const today = new Date();

  const date1 = Date.parse(
    `${today.getDay()}/${today.getMonth()}/${today.getFullYear()} ${time1}`,
  );
  const date2 = Date.parse(
    `${today.getDay()}/${today.getMonth()}/${today.getFullYear()} ${time2}`,
  );
  if (date1 > date2) {
    return Math.floor(new Date(date1 - date2).getTime() / 60000);
  }
};

export const dateDiffInDays = (a, b) => {
  const milliSecondsPerDay = 1000 * 60 * 60 * 24;
  // Discard the time and time-zone information.
  const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
  const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

  return Math.floor((utc2 - utc1) / milliSecondsPerDay);
};

export const applyEllipsis = (text, charLimit) => {
  let textString = String(text);
  textString = textString.slice(0, charLimit);
  if (text?.length > charLimit) {
    textString = `${textString}...`;
  }
  return textString;
};

export const getPhoneNumberFromCampusObj = (
  campus: CampusLocationInterface,
) => {
  const contact = campus?.contact;
  if (!contact) {
    return '';
  }
  const phoneSuffix = contact?.phone?.number;
  const phonePrefix = contact?.phone?.prefix;
  if (!phoneSuffix || !phonePrefix) {
    return '';
  }
  const phoneNumber = `${phonePrefix}${phoneSuffix}`;
  return phoneNumber;
};

export const SplitAndCapitalise = (str) => {
  let i;
  const words = str.split('_');
  // eslint-disable-next-line no-plusplus
  for (i = 0; i < words.length; i++) {
    words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1);
  }
  return words.join(' ');
};

export const shuffle = (array): [] => {
  let currentIndex = array.length;
  let randomIndex: number;

  // While there remain elements to shuffle.
  while (currentIndex !== 0) {
    // Pick a remaining element.
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ];
  }

  return array;
};

export const addPadding = (
  val,
  paddingStartAfter: number,
  paddingVal: string,
) => {
  const stringVal = String(val);
  return stringVal.padStart(paddingStartAfter, paddingVal);
};

export const formatTimeToHrsMinSec = (duration) => {
  // Hours, minutes and seconds
  const hrs = ~~(duration / 3600);
  const mins = ~~((duration % 3600) / 60);
  const secs = ~~duration % 60;

  // Output like "1:01" or "4:03:59" or "123:03:59"
  let ret = '';

  if (hrs > 0) {
    ret += `${hrs}:${mins < 10 ? `0${mins}` : mins}:${
      secs < 10 ? `0${secs}` : secs
    }`;
    return ret;
  }

  ret += `${mins < 10 ? `0${mins}` : mins}:${secs < 10 ? '0' : ''}`;
  ret += `${secs}`;

  return ret;
};

export const handleStorePress = (webOsName) => {
  const appURL = `https://apps.apple.com/in/app/32nd-hub/${AppConstants.APP_STORE_ID}`;
  const playURL = `http://play.google.com/store/apps/details?id=${AppConstants.PLAY_STORE_PROD_ID}`;
  if (webOsName === 'iOS') {
    Linking.openURL(appURL);
  } else if (webOsName === 'iPadOS') {
    Linking.openURL(appURL);
  } else if (webOsName === 'Android') {
    Linking.openURL(playURL);
  } else if (webOsName === 'Windows') {
    Linking.openURL(playURL);
  } else if (webOsName === 'Mac OS') {
    Linking.openURL(appURL);
  }
};
export const openAppOrRedirectToPlatformStore = async (deeplink: string) => {
  const baseUrl =
    process.env.EXPO_PUBLIC_APP_ENV === Environments.PRODUCTION
      ? 'https://club.32nd.com/redirectToPlatformStore'
      : 'https://staging.club.32nd.com/redirectToPlatformStore';
  const url = deeplink?.length ? `${baseUrl}?deeplink=${deeplink}` : baseUrl;
  if (await Linking.canOpenURL(url)) await Linking.openURL(url);
};

export const encodeDeeplink = (
  screen: string,
  params: Record<string, any>,
): string => {
  let enceodedDL = screen;
  const paramNames = Object.keys(params);

  if (paramNames?.length) {
    enceodedDL += '?';
    paramNames.forEach((paramName, i) => {
      const isLastItem = i === paramNames.length - 1;
      enceodedDL = `${enceodedDL}${paramName}=${params[paramName]}`;
      if (!isLastItem) {
        enceodedDL += '&';
      }
    });
  }
  enceodedDL = enceodedDL.replace(/:/g, '%3A');
  enceodedDL = enceodedDL.replace(/\?/g, ':::');
  enceodedDL = enceodedDL.replace(/=/g, '::');
  enceodedDL = enceodedDL.replace(/&/g, ':');
  return enceodedDL;
};
export const areAllValueInArrSame = (arr) => arr.every((v) => v === arr[0]);

export const getFullName = (first, last) =>
  `${first}${last !== null ? ` ${last}` : ''}`;
export const delay = (time) => {
  const currentTime = Date.now();
  // eslint-disable-next-line no-empty
  while (Date.now() <= currentTime + time) {}
};

// ------ convert time from seconds to HH:MM format ------
export const convertTime = (sec) => {
  const d = Number(sec);
  const h = Math.round(d / 3600);
  const m = h >= 1 ? Math.floor((d % 3600) / 60) : Math.round((d % 3600) / 60);

  const hDisplay =
    h > 0 ? `${h.toString().length > 1 ? `${h}` : `${0}${h}`}` : '00';
  const mDisplay =
    m > 0 ? `${m.toString().length > 1 ? `${m}` : `${0}${m}`}` : '00';
  return `${hDisplay}:${mDisplay}`;
};

// ------ getting time either as 7mins or 7hr from seconds ------
export const getHoursAndMinutesFromSeconds = (sec) => {
  const d = Number(sec);
  const hours = Math.floor(d / 3600);
  const mins = Math.floor((d % 3600) / 60);
  if (hours < 1) {
    return `${mins}mins`;
  }

  return `${hours}hr`;
};

export const commitMutationPromise = (mutation, variables) => {
  return new Promise((resolve, reject) => {
    commitMutation(environment, {
      mutation,
      variables,
      onCompleted: (response, errors) => {
        if (errors) {
          reject(errors);
        } else {
          resolve(response);
        }
      },
      onError: (error) => {
        reject(error);
      },
    });
  });
};

// ------ getting time either as 7 mins or 7 hr ago from seconds ------
export const getHoursAndMinutesAgoFromSeconds = (
  sec,
  postText = 'ago',
): string => {
  const d = Number(sec);
  const hours = Math.floor(d / 3600);
  const mins = Math.floor((d % 3600) / 60);
  if (hours < 1) {
    return mins <= 1
      ? `${mins < 1 ? 1 : mins} min ${postText}`
      : `${mins} mins ${postText}`;
  }

  return hours <= 1 ? `${hours} hr ${postText}` : `${hours} hrs ${postText}`;
};

// ------ getting time either as 7hrs from seconds ------
export const getHoursFromSeconds = (sec): string => {
  const d = Number(sec);
  const hours = Math.floor(d / 3600);
  const mins = Math.floor((d % 3600) / 60);
  if (hours < 1 && mins > 0) {
    return `1hr`;
  }
  if (hours >= 1 && mins > 1) {
    return `${hours + 1}hrs`;
  }
  return `${hours} hrs`;
};

export const getHoursFromSecondsRounded = (sec): string => {
  const d = Number(sec);
  const hours = Math.floor(d / 3600);
  const mins = Math.floor((d % 3600) / 60);
  if (hours < 1 && mins > 0) {
    return `1hr`;
  }
  return `${hours} hrs`;
};

export const maskedNumber = (str: string): string => {
  return str?.length ? `${str.slice(0, 2)}xxxx${str.slice(6, 10)}` : '';
};

export const toISOStringWithTimezone = (date: Date) => {
  const tzOffset = -date.getTimezoneOffset();
  const diff = tzOffset >= 0 ? '+' : '-';
  const pad = (n) => `${Math.floor(Math.abs(n))}`.padStart(2, '0');
  return `${date.getFullYear()}-${pad(date.getMonth() + 1)}-${pad(
    date.getDate(),
  )}T${pad(date.getHours())}:${pad(date.getMinutes())}:${pad(
    date.getSeconds(),
  )}${diff}${pad(tzOffset / 60)}:${pad(tzOffset % 60)}`;
};

export const getTagLine = (
  cusines: {
    name: string;
  }[],
): string => {
  try {
    let str = '';
    for (let index = 0; index < cusines.length; index += 1) {
      if (index === 3) break;
      str +=
        SplitAndCapitalise(cusines[index].name) +
        (index < cusines.length - 1 && index < 2 ? ', ' : '');
    }
    return str;
  } catch (error) {
    return '';
  }
};

export const openInstagram = () => {
  const username = '32ndofficial'; // Replace with the Instagram username
  const instaId = '101914657873323';
  const text = 'Check out my profile on Instagram!'; // The text you want to share
  const url = `https://www.instagram.com/direct/t/${instaId}`; // Replace with the Instagram profile URL
  const instagramURL = `https://ig.me/m/${username}`;

  Linking.canOpenURL(instagramURL)
    .then((supported) => {
      if (supported) {
        Linking.openURL(instagramURL);
      }
      // Fallback to opening the Instagram profile in a web browser
      Linking.openURL(url);
    })
    .catch((error) => console.error('Error opening Instagram:', error));
};

export const openWhatsApp = async (text, phone = defaultConatactNumber32ND) => {
  const whatsappURL = `whatsapp://send?phone=${phone}&text=${text}`;
  const waWebURL = `https://api.whatsapp.com/send?phone=${phone}&text=${text}`;

  Linking.canOpenURL(whatsappURL)
    .then((supported) => {
      if (supported) {
        if (Platform.OS === 'web') {
          Linking.openURL(waWebURL);
          return;
        }
        Linking.openURL(whatsappURL);
        return;
      }
      // Fallback to opening WhatsApp in a web browser
      Linking.openURL(waWebURL);
    })
    .catch((error) => console.error('Error opening WhatsApp:', error));
};

export const numberWithCommas = (num: number) => {
  return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
};

export const isToday = (date: string /* YYYY-MM-DD */): boolean => {
  return moment().isSame(date, 'date');
};

export const daysDiffInArray = (d = [], daysDiff = 1) => {
  const arraySize = d?.length;
  if (arraySize === 0 || arraySize === 1) {
    return false;
  }

  let foundAnyDiff = false;
  for (let index = 0; index < arraySize - 1; index += 1) {
    const elementOne = d[index];
    const elementTwo = d[index + 1];
    if (elementTwo - elementOne > daysDiff) {
      foundAnyDiff = true;
    }
  }
  return foundAnyDiff;
};

export const getFormatedDate = (
  date1: Date,
  format: DateFormatsEnum,
  date2?: Date,
) => {
  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
  const monthsFull = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  const currentDate = new Date(date1);
  const day = currentDate.getDate();
  const dayName = days[currentDate.getDay()];
  const month =
    Platform.OS !== 'android'
      ? currentDate.toLocaleString('en-US', { month: 'short' })
      : months[currentDate.getMonth()];
  const monthFull =
    Platform.OS !== 'android'
      ? currentDate.toLocaleString('en-US', { month: 'long' })
      : monthsFull[currentDate.getMonth()];
  const year = currentDate.getFullYear();
  const hours = currentDate.getHours() % 12 || 12; // Convert to 12-hour format
  const minutes = currentDate.getMinutes().toString().padStart(2, '0'); // Add leading zero to minutes if necessary
  const amPm = currentDate.getHours() < 12 ? 'AM' : 'PM'; // Determine AM/PM based on hour
  const monthNumber =
    currentDate.getMonth() < 9
      ? `0${currentDate.getMonth() + 1}`
      : currentDate.getMonth() + 1;

  if (format === DateFormatsEnum.DateMonthYear) {
    return `${day} ${month}, ${year}`;
  }
  if (format === DateFormatsEnum.DayDateMonth) {
    return `${dayName}, ${day} ${month}`;
  }
  if (format === DateFormatsEnum.DateMonthYearDob) {
    return `${day}/${monthNumber}/${year}`;
  }
  if (format === DateFormatsEnum.DayDateMonthYear) {
    return `${dayName} ${day} ${month} ${year}`;
  }
  if (format === DateFormatsEnum.MonthYear) {
    return `${month} ${year}`;
  }
  if (format === DateFormatsEnum.Year) {
    return `${year}`;
  }
  if (format === DateFormatsEnum.MonthFull) {
    return `${monthFull}`;
  }
  if (format === DateFormatsEnum.RangeMonthYear && date2) {
    const newDate = new Date(date2);
    const day2 = newDate.getDate();
    const month2 =
      Platform.OS !== 'android'
        ? newDate.toLocaleString('en-US', { month: 'short' })
        : months[newDate.getMonth()];
    const year2 = newDate.getFullYear();
    return `${day} ${month}, ${year} - ${day2} ${month2}, ${year2}`;
  }
  if (format === DateFormatsEnum.TIME) {
    return `${hours}:${minutes} ${amPm}`;
  }

  if (format === DateFormatsEnum.MS) {
    return currentDate.valueOf();
  }

  return `${day} ${month}, ${year}`;
};

export const hasWhiteSpace = (s) => {
  return /\s/g.test(s);
};

export const getValetWorkLogStatus = (status) => {
  if (status === 'available') {
    return {
      text: 'Available',
      color: 'success.500',
    };
  }
  if (status === 'busy') {
    return {
      text: 'Task Ongoing',
      color: 'warning.500',
    };
  }
  if (status === 'break') {
    return {
      text: 'On Break',
      color: 'error.500',
    };
  }
  if (status === 'unavailable') {
    return {
      text: 'Unavailable',
      color: 'grey.300',
    };
  }
  return {
    text: 'Unavailable',
    color: 'grey.300',
  };
};

export const getTimeDiff = (dataOne, dateTwo) => {
  const DateOne = moment(dataOne);
  const DateTwo = moment(dateTwo);
  const duration = moment.duration(DateTwo.diff(DateOne));
  // in miliseconds
  return duration;
};

export const durationIntoHoursMinutes = (duration) => {
  let timeStr = '';
  if (!duration) {
    return timeStr;
  }
  const hours = Math.floor(duration.hours());
  const minutes = Math.floor(duration.minutes());
  const seconds = Math.floor(duration.seconds());

  if (hours) {
    timeStr = `${hours}h${minutes ? ` ${minutes}m` : ''}${
      seconds ? ` ${seconds}s` : ''
    }`;
  } else if (minutes) {
    timeStr = `${minutes}m${seconds ? ` ${seconds}s` : ''}`;
  } else if (!hours && !minutes) {
    timeStr = `${seconds}s`;
  }
  return timeStr;
};

export const camelCaseToSeparatedWords = (t) => {
  return t
    .replace(/([a-z])([A-Z])/g, '$1 $2') // Add space between lowercase and uppercase letters
    .replace(/([A-Z])([A-Z][a-z])/g, '$1 $2') // Add space between consecutive uppercase letters followed by a lowercase letter
    .split(' ') // Split the string into an array of words
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize the first letter of each word
    .join(' '); // Join the words back into a string with spaces
};

export const fancyTimeFormat = (duration) => {
  // Hours, minutes and seconds
  const hrs = ~~(duration / 3600);
  const mins = ~~((duration % 3600) / 60);
  const secs = ~~duration % 60;

  // Output like "01:01" or "04:03:59" or "123:03:59"
  let ret = '';

  if (hrs > 0) {
    ret += `${hrs}:${mins < 10 ? '0' : ''}`;
  }

  ret += `${mins > 10 ? mins : `0${mins}`}:${secs < 10 ? '0' : ''}`;
  ret += `${secs}`;

  return ret;
};

export const discountAndWalletTextMaker = (
  currentBalance = 0,
  discountPercentage = 0,
  payBillCashback = 0,
) => {
  const discounts = [];

  if (discountPercentage) {
    discounts.push(`Flat ${discountPercentage}% OFF`);
  }
  if (payBillCashback) {
    discounts.push(`${payBillCashback}% Cashback`);
  }
  if (currentBalance) {
    discounts.push(`₹${currentBalance} OFF`);
  }

  // Join the discounts with ' + ' only if there are multiple discounts
  const result =
    discounts.length > 1 ? discounts.join(' + ') : discounts.join('');
  return result;
};

export const discountAndWalletTextMakerV2 = (
  currentBalance = 0,
  discountPercentage = 0,
  payBillCashback = 0,
) => {
  const discounts = [];

  if (discountPercentage) {
    discounts.push(`${discountPercentage}% OFF`);
  }
  if (payBillCashback) {
    discounts.push(`${payBillCashback}% Cashback`);
  }
  if (currentBalance && (!payBillCashback || !discountPercentage)) {
    discounts.push(`₹${currentBalance} OFF`);
  }

  // Join the discounts with ' + ' only if there are multiple discounts
  const result =
    discounts.length > 1 ? discounts.join(' + ') : discounts.join('');
  return result;
};
export const toRupees = (amount: number | string) => `₹${amount}`;

// export const commaSepNum = (num: number) => num.toLocaleString('en-IN', { style: 'currency', currency: 'INR' });
export const commaSepNum = (num: number) =>
  num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

export const resolveColor = (status: ColorStatusEnum): string => {
  switch (status) {
    case ColorStatusEnum.SUCCESS:
      return 'success.500';
    case ColorStatusEnum.ERROR:
      return 'error.500';
    case ColorStatusEnum.WARNING:
      return 'warning.500';
    case ColorStatusEnum.DEFAULT:
      return 'grey.400';
    default:
      return 'grey.400';
  }
};

export const convertNumber = (number) => {
  let num = number;
  num = num.replace(num.substring(4, 7), 'xxx');
  return num;
};

export const toTitleCase = (str: string): string => {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + str.substring(1).toLowerCase();
  });
};

export const formatPrice = (price: number): string =>
  toRupees(commaSepNum(price));

export const dateUtils = {
  isToday: (date: Date) => {
    const today = new Date();
    return dateUtils.isSameDate(date, today);
  },

  isYesterday: (date: Date) => {
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);

    return dateUtils.isSameDate(date, yesterday);
  },

  /**
   * Checks if the passed date is a past date relative to today's date.
   * @param {Date} date - The date to check.
   * @returns {boolean} - Returns true if the date is in the past, otherwise false.
   */
  isPastDate: (date: Date): boolean => {
    const today = moment().startOf('day'); // Start of today's date (00:00:00)
    const pastDate = moment(date).startOf('day'); // Start of the passed date (00:00:00)
    return pastDate.isBefore(today);
  },
  /**
   * Checks if the passed date is a future date relative to today's date.
   * @param {Date} date - The date to check.
   * @returns {boolean} - Returns true if the date is in the future, otherwise false.
   */
  isFutureDate: (date: Date) => {
    const today = moment().startOf('day'); // Start of today's date (00:00:00)
    const futureDate = moment(date).startOf('day'); // Start of the passed date (00:00:00)
    return futureDate.isAfter(today);
  },

  isCurrentMonth: (date: Date) => {
    const today = new Date();
    return dateUtils.isSameMonth(today, date);
  },

  isSameDate: (date1: Date, date2: Date) => {
    const y1 = date1.getFullYear();
    const y2 = date2.getFullYear();

    const m1 = date1.getMonth();
    const m2 = date2.getMonth();

    const d1 = date1.getDate();
    const d2 = date2.getDate();

    if (d1 !== d2 || m1 !== m2 || y1 !== y2) return false;

    return true;
  },

  isSameMonth: (date1: Date, date2: Date) => {
    const y1 = date1.getFullYear();
    const y2 = date2.getFullYear();

    const m1 = date1.getMonth();
    const m2 = date2.getMonth();

    if (m1 !== m2 || y1 !== y2) return false;
    return true;
  },

  toStartOfDay: (date: Date): Date => {
    const dt = new Date(date);
    dt.setHours(0, 0, 0);
    return dt;
  },
  toEndOfDay: (date: Date): Date => {
    const dt = new Date(date);
    dt.setHours(23, 59, 59);
    return dt;
  },
  toStartOfMonth: (date: Date): Date => {
    const dt = new Date(date);
    dt.setDate(1);
    dt.setHours(0, 0, 0);
    return dt;
  },
  toEndOfMonth: (date: Date): Date => {
    const dt = new Date(date);
    const modfifiedDate = new Date(
      dt.getFullYear(),
      dt.getMonth() + 1, // select next from from the current, will be restored to correct month by passing day as "0"
      0, // passing "0" as day will do the trick of selecting previous day and hence will restore the month
    );
    const lastDayOfMonth = modfifiedDate.getDate();
    dt.setDate(lastDayOfMonth);
    dt.setHours(23, 59, 59);
    return dt;
  },
};

export const arrayRange = (start, stop, step) => {
  const array = [];
  for (let i = start; i <= stop; i += step) {
    array.push(i);
  }
  return array;
};

export const getDayFromDate = (date, dateIncrement = 0) => {
  const currentDate = new Date(date);
  const incrementedDate = new Date(
    currentDate.setDate(currentDate.getDate() + dateIncrement),
  );
  const days = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ];
  const dayName = days[incrementedDate.getDay()];
  return dayName;
};

export const CheckIfDateLiesInWeekend = (date, dateIncrement = 0) => {
  const currentDate = new Date(date);
  const incrementedDate = new Date(
    currentDate.setDate(currentDate.getDate() + dateIncrement),
  );
  if (
    incrementedDate.getDay() === 0 ||
    incrementedDate.getDay() === 5 ||
    incrementedDate.getDay() === 6
  ) {
    return true;
  }
  return false;
};

export const checkIfCurrentTimeLiesBetweenTwoTimes = () => {
  // Get the current time
  const currentTime = new Date();

  // Set the start and end times
  const startTime = new Date();
  startTime.setHours(6, 0, 0); // start time

  const endTime = new Date();
  endTime.setHours(18, 0, 0); // end time

  // Check if the current time is between the start and end times
  if (currentTime >= startTime && currentTime <= endTime) {
    return MealTimeEnum.LUNCH;
  }
  return MealTimeEnum.DINNER;
};

export const capitalizeAfterSpace = (str) => {
  return str
    .toLowerCase()
    .split(' ')
    .map((char) => char.charAt(0).toUpperCase() + char.slice(1))
    .join(' ');
};

export const getCouponImg = (type: COUPON_TYPE) => {
  switch (type) {
    case COUPON_TYPE.FLAT_CASHBACK:
      return require('../../assets/images/coins.webp');
    case COUPON_TYPE.FLAT_DISCOUNT:
      return require('../../assets/images/banknote.webp');
    case COUPON_TYPE.PERCENTAGE_OFF:
      return require('../../assets/images/offers.png');
    case COUPON_TYPE.PERCENTAGE_CASHBACK:
      return require('../../assets/images/coins.webp');
    default:
      return require('../../assets/images/offers.png');
  }
};

export const calculateMaxHeight = (screenHeight, maxHeightPercentage) => {
  return Math.round(screenHeight * maxHeightPercentage);
};

export const OpenMap = (lat: string, long: string) => {
  const latLng = `${lat},${long}`;
  let url = '';
  if (Platform.OS === 'web') {
    url = `https://maps.google.com/maps?q=${latLng}`;
  } else {
    const scheme = Platform.select({
      ios: 'maps://0,0?q=',
      android: 'geo:0,0?q=',
    });
    const label = 'Custom Label';
    url = Platform.select({
      ios: `${scheme}${label}@${latLng}`,
      android: `${scheme}${latLng}(${label})`,
    });
  }
  Linking.openURL(url);
};

export const durationMaker = (
  eventTimings: [],
  startTime = '',
  endTime = '',
) => {
  let res = 'Flexible';
  const startTimeA = eventTimings?.[0]?.slot?.startTime ?? startTime;
  const endTimeA = eventTimings?.[0]?.slot?.endTime ?? endTime;
  const TimeDiff = moment(endTimeA).unix() - moment(startTimeA).unix();
  if (TimeDiff > 0) {
    const mins = TimeDiff / 60;
    res = `${String(mins.toFixed(0))} mins`;
  }
  return res;
};

export const daysMaker = (eventTimings: []) => {
  let res = [];
  let dayString = 'Multiple';
  if (eventTimings?.length) {
    eventTimings.forEach((item) => {
      const selectedDay = moment(item?.date).format('ddd');
      if (res?.length) {
        res = res.filter(function (itemInr) {
          return itemInr !== selectedDay;
        });
      }
      res.push(selectedDay);
    });
  }
  if (res?.length > 3) {
    dayString = `${res.slice(0, 3).join(', ')} and more`;
  } else if (res?.length > 1) {
    dayString = res.join(', ');
  } else if (res?.length === 1) {
    dayString = res?.[0];
  }
  return dayString;
};

export const dynamicHeightMaker = (width: number, ar: number) => {
  const newHeight = width * ar;
  return newHeight;
};
export const secondsToHms = (d: number) => {
  d = Number(d);
  const h = Math.floor(d / 3600);
  const m = Math.floor((d % 3600) / 60);
  const s = Math.floor((d % 3600) % 60);

  const hDisplay = h > 0 ? (h > 9 ? `${h} : ` : `0${h} : `) : '00 : ';
  const mDisplay = m > 0 ? (m > 9 ? `${m} : ` : `0${m} : `) : '00 : ';
  const sDisplay = s > 0 ? (s > 9 ? s : `0${s}`) : '00';
  return hDisplay + mDisplay + sDisplay;
};
/**
 * Months are rounded
 * @param days
 * @returns Converts number of days to format in `years` and `months`
 */
export const daysToMonthYear = (days: number) => {
  const avgMonthSize = 30.42;
  let months = Math.round(days / avgMonthSize);
  const years = Math.floor(months / 12);
  months %= 12;
  let yearsLabel = '';
  let monthsLabel = '';
  if (years > 0) {
    yearsLabel = years === 1 ? `${years} year ` : `${years} years `;
  }
  if (months > 0) {
    monthsLabel = months === 1 ? `${months} month ` : `${months} months `;
  }
  return `${yearsLabel}${monthsLabel}`;
};

/**
 * Months are rounded
 * @param days
 * @returns Converts number of days to `months`
 */
export const daysToMonths = (days: number) => {
  const avgMonthSize = 30.42;
  const months = Math.round(days / avgMonthSize);
  let monthsLabel = '';
  monthsLabel = months === 1 ? `${months} month ` : `${months} months `;
  return monthsLabel;
};

export const checkValidMongoIdByLength = (id: string) => {
  if (id?.length === 24) return true;
  return false;
};

export const modifyEventsPrice = (eventTiming) => {
  let priceVal = !isEmpty(eventTiming[0]?.slot?.availableTickets)
    ? eventTiming[0]?.slot?.availableTickets?.reduce((acc, curr) => {
        if (curr?.isActive) {
          const newPrice = curr?.price / curr?.personAllowed;
          if (newPrice < acc) {
            return newPrice;
          }
        }
        return acc;
      }, Number.MAX_SAFE_INTEGER)
    : 0;
  priceVal = priceVal === Number.MAX_SAFE_INTEGER ? 0 : priceVal;
  return priceVal;
};

export const blobToBase64 = (blob) => {
  return new Promise((resolve, _) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.readAsDataURL(blob);
  });
};

export const checkEventStatus = (data: any) => {
  if (moment(data?.endTime) < moment()) return 'Completed';

  const startTime = moment(data?.startTime);
  const current = moment();
  if (startTime.diff(current, 'days') > 0) {
    return `${startTime.diff(current, 'days')} ${
      startTime.diff(current, 'days') > 1 ? 'days' : 'day'
    } to go`;
  }
  if (startTime.diff(current, 'hours') > 0) {
    return `${startTime.diff(current, 'hours')} ${
      startTime.diff(current, 'hours') > 1 ? 'hours' : 'hour'
    } to go`;
  }
  if (startTime.diff(current, 'minute') > 0) {
    return `${startTime.diff(current, 'minute')} ${
      startTime.diff(current, 'minute') > 1 ? 'minutes' : 'minute'
    } to go`;
  }
  return `Ongoing`;
};

export const ReturnJuspayPaymentStatus = (orderStatus: JUSPAY_ORDER_STATUS) => {
  switch (orderStatus) {
    case JUSPAY_ORDER_STATUS.CHARGED:
    case JUSPAY_ORDER_STATUS.COD_INITIATED:
    case JUSPAY_ORDER_STATUS.PARTIAL_CHARGED:
    case JUSPAY_ORDER_STATUS.VOIDED:
    case JUSPAY_ORDER_STATUS.AUTHORIZED:
      return PaymentStatusEnum.SUCCESSFUL;
    case JUSPAY_ORDER_STATUS.NEW:
    case JUSPAY_ORDER_STATUS.PENDING_VBV:
    case JUSPAY_ORDER_STATUS.AUTHORIZING:
    case JUSPAY_ORDER_STATUS.STARTED:
    case JUSPAY_ORDER_STATUS.CAPTURE_INITIATED:
    case JUSPAY_ORDER_STATUS.VOID_INITIATED:
      return PaymentStatusEnum.PENDING;
    case JUSPAY_ORDER_STATUS.AUTHENTICATION_FAILED:
    case JUSPAY_ORDER_STATUS.AUTHORIZATION_FAILED:
    case JUSPAY_ORDER_STATUS.JUSPAY_DECLINED:
    case JUSPAY_ORDER_STATUS.CAPTURE_FAILED:
    case JUSPAY_ORDER_STATUS.VOID_FAILED:
    case JUSPAY_ORDER_STATUS.NOT_FOUND:
      return PaymentStatusEnum.FAILED;
    default:
      return PaymentStatusEnum.PENDING;
  }
};

export const getEventCoverImage = (eventData): string => {
  const coverImg = eventData?.media?.cover?.filter(
    (img) => img?.mediaType === 'image',
  );
  return coverImg?.length ? coverImg[0]?.mediaURL ?? '' : '';
};
export const capitalizeUserName = (
  user: {
    name: { first: string; last?: string };
  } & Record<string, any>,
) => {
  const { first, last } = user?.name ?? {};
  let capitalisedUserName = '';
  if (first?.length) {
    capitalisedUserName = Capitalise(first.trim());
  }
  if (last?.length) {
    capitalisedUserName = `${capitalisedUserName} ${Capitalise(last.trim())}`;
  }
  return capitalisedUserName;
};

/**
 * converts 1000 to 1K or 1_000_000 to 1M and so on
 */
export const toShortHandNumber = (num: number, withAccuracy = true): string => {
  const absNum = Math.abs(num);
  const oneMillion = 1_000_000;
  const oneThousand = 1_000;

  if (absNum >= oneMillion) {
    const millions = Math.floor(num / oneMillion);
    const remainder = num % oneMillion;

    if (withAccuracy) {
      return remainder > 0 && remainder < 100000
        ? `${millions}M+`
        : `${parseFloat((num / oneMillion).toFixed(1))}M`;
    }
    return `${parseFloat((num / oneMillion).toFixed(1))}M`;
  }
  if (absNum >= oneThousand) {
    const thousands = Math.floor(num / oneThousand);
    const remainder = num % oneThousand;
    if (withAccuracy) {
      if (thousands < 50000) {
        return remainder > 0 && remainder < 500
          ? `${thousands}K+`
          : `${parseFloat((num / oneThousand).toFixed(1))}K`;
      }
      return `${parseFloat((num / oneThousand).toFixed(1))}K`;
    }
    return `${parseFloat((num / oneThousand).toFixed(0))}K`;
  }
  return num.toString();
};

export const getRandomInt = (min: number, max: number): number => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

export const convertToHashmap = (array: any, keyBy: any) => {
  const hashMap = array.reduce((result, item) => {
    return { ...result, [item[`${keyBy}`]]: item };
  }, {});
  return hashMap;
};

export const compareVersions = (v1: string, v2: string): boolean => {
  const parts1 = v1.split('.').map(Number); // Split and convert to numbers
  const parts2 = v2.split('.').map(Number);

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < Math.max(parts1.length, parts2.length); i++) {
    const num1 = parts1[i] || 0; // Use 0 if undefined
    const num2 = parts2[i] || 0;

    if (num1 < num2) return true; // v1 > v2
    if (num1 > num2) return false; // v1 < v2
  }

  return false; // v1 === v2
};

export const paramsToQueryString = (params: Record<string, any>): string => {
  const searchParams = new URLSearchParams();

  const keys = Object.keys(params);

  keys.forEach((key) => {
    if (key in params) {
      const value = params[key];
      if (Array.isArray(value)) {
        // Handle array params
        value.forEach((item) => searchParams.append(key, item));
      } else if (typeof value === 'object' && value !== null) {
        // Handle object params (convert to JSON string)
        searchParams.append(key, JSON.stringify(value));
      } else if (value !== undefined && value !== null) {
        // Handle primitive values
        searchParams.append(key, value);
      }
    }
  });

  return searchParams.toString();
};

export const range = (start, end, step = 1) => {
  const result = [];
  for (let i = start; i < end; i += step) {
    result.push(i);
  }
  return result;
};

export const getPathFromRoute = (route) => {
  const { name, params } = route ?? {};
  const path = `${AppConstants.WEB_URL}/${name}${
    params ? `?${new URLSearchParams(params).toString()}` : ''
  }`;
  return path;
};
